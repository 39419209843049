import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Container from '/imports/core/ui/atoms/Container';

class Header extends PureComponent {
  static propTypes = {
    isMobile: PropTypes.bool,
    transparent: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  };
  render() {
    const { isMobile, transparent, children } = this.props;
    return (
      <Section transparent={transparent} isMobile={isMobile}>
        <HeaderContainer isMobile={isMobile}>{children}</HeaderContainer>
      </Section>
    );
  }
}

const Section = styled.header`
  width: 100%;
  background: ${(p) => (p.transparent ? 'transparent' : p.theme.colors.darkBlack)};
  z-index: 1000;
  position: absolute;
  ${({ theme }) => theme.max('sm')`
    padding: 0 10px;
  `}
  ${({ theme }) => theme.max('md')`
    position: static;
  `}
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      ${theme.max('sm')`
        padding: 0;
  `}
    `}
  ${(p) =>
    p.isMobile &&
    css`
      text-align: center;
    `}
`;

const HeaderContainer = styled((p) => <Container {...p} />)`
  width: 100%;
  height: 100%;
  padding: 30px 40px;
  ${(p) =>
    p.isMobile &&
    css`
      padding-top: 44px;
    `};
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      text-align: right !important;
    `}
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      padding: 32px;
    `}
`;

export default Header;
