import styled, { css } from 'styled-components';

const Heading = styled.p`
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 1.56px;
  text-align: center;
  color: var(--black);
  margin: 0;
  ${(p) =>
    p.isMobile &&
    css`
      font-size: 20px;
    `}
`;

export default Heading;
