import React, { useState } from 'react';
import nookies, { destroyCookie } from 'nookies';
import { browserName, osName } from 'react-device-detect';
import { useForm, Controller } from 'react-hook-form';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { ERROR_MESSAGES } from '/imports/core/api/constants';
import FieldLabel from '/imports/generator/ui/atoms/FieldLabel';
import { useResponsive } from '/imports/core/api/responsiveContext';
import FormGroup from '/imports/core/ui/atoms/FormGroup';
import { getRoute, isBlogDomain, isJobTrackEnable, savedDataOnIndex } from '/lib/helpers';
import { Heading, SubHeading } from '/imports/login/ui/atoms';
import Label from '/imports/core/ui/atoms/Label';
import { replaceString } from '/imports/core/ui/helpers';
import useIntl from '/imports/core/api/useIntl';
import env from '/env';

import Button from '/imports/core/ui/atoms/Button';
import ValidatedInput from 'imports/core/ui/atoms/ValidatedInput';

const validateEmail = (value) => {
  if (!value) {
    return ERROR_MESSAGES.required;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value)) {
    return ERROR_MESSAGES.email;
  }
  return true;
};

const validatePassword = (value) => {
  if (!value) {
    return ERROR_MESSAGES.required;
  }
  return true;
};

const LoginForm = ({ router, isMobile, onSuccess, onError }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getFieldState,
  } = useForm({
    mode: 'all',
  });
  const { t, locale } = useIntl();
  const { host } = useResponsive();
  const isJobTrack = isJobTrackEnable();

  const [emailNotFound, setEmailNotFound] = useState();
  const [usePassword, setUsePassword] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (data) => {
    const { token } = nookies.get({});
    const { hostname } = window.location;
    if (token) destroyCookie(null, 'token');
    const fetchOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    try {
      const params = {
        email: data?.email.toLowerCase(),
        os: osName || '',
        browser: browserName || 'unknown',
        origin: `${window.location.origin}${getRoute('', locale, false, host, isBlogDomain(host))}`,
        language: locale,
      };

      if (usePassword) {
        params.password = data.password;
      }

      const response = await fetch(`${env.BASE_API}/auth/login`, {
        ...fetchOptions,
        body: JSON.stringify(params),
      });

      if (response.status === 404) {
        setEmailNotFound(true);
        return;
      }

      if (response.status === 401) {
        const text = await response.text();
        if (text === 'Email and Password does not match') {
          const errMsg = t(ERROR_MESSAGES.emailPassowrdNotMatch);
          setError(errMsg);
          return;
        } else if (text === 'User does not have password, Please try with email login') {
          const errMsg = t(ERROR_MESSAGES.userNotHavePassword);
          setError(errMsg);
          return;
        } else {
          const errMsg = text;
          setError(errMsg);
          return;
        }
      }

      if (response.status > 399) {
        const errMsg = await response.text();
        throw new Error(errMsg);
      }

      if (usePassword) {
        const { link } = await response.json();
        savedDataOnIndex('domain', hostname);
        if (link) {
          window.location.href = link;
        }
        return;
      }

      onSuccess(data?.email);
    } catch (e) {
      onError(e.message);
    }
  };

  const errorEmail = replaceString(t(ERROR_MESSAGES.emailNotFound), {
    '/onboard/start': getRoute('/onboard/start', locale, false, host, isBlogDomain(host)),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Using Controller component to register input */}
      <FormGroupAtom>
        <StyleHeading isMobile={isMobile}>{t('signin')}</StyleHeading>
        <StyleSubHeading isMobile={isMobile}>
          {usePassword ? t('signin_heading2') : t('signin_heading')}
        </StyleSubHeading>
        <FormWrapper>
          {isJobTrack ? (
            <FieldLabel htmlFor="email-address" label={t('email_addres')} background="transparent" summary />
          ) : (
            <Label htmlFor="email-address">{t('email_addres')}</Label>
          )}
          <Controller
            name="email"
            control={control}
            defaultValue={(router && router.query && router.query.email) || ''}
            rules={{ validate: validateEmail }}
            render={({ field }) => (
              <Input
                {...field}
                id="email-address"
                type="text"
                isValid={getFieldState('email').invalid}
                errorMessageCustomCss={emailNotFound ? errorEmail : t(errors?.email?.message)}
                customErrorStyle={errorStyle}
                emailNotFound={emailNotFound}
              />
            )}
          />
        </FormWrapper>

        {usePassword && (
          <PasswordWrapper>
            {isJobTrack ? (
              <FieldLabel htmlFor="password" label={t('tc_que3_d')} background="transparent" summary />
            ) : (
              <Label htmlFor="password">{t('tc_que3_d')}</Label>
            )}
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{ validate: validatePassword }} // Registering custom validator
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    type="password"
                    isValid={getFieldState('password').invalid}
                    errorMessageCustomCss={t(errors?.password?.message)}
                    customErrorStyle={errorStyle}
                  />
                </>
              )}
            />
          </PasswordWrapper>
        )}

        {error && <ErrorText>{error}</ErrorText>}

        <SignInButton type="submit">{t('checkout_continue')}</SignInButton>
        <StyledButton type="button" onClick={() => setUsePassword((prev) => !prev)} link={1}>
          <TextWrap>{!usePassword ? t('use_password') : t('use_link')} </TextWrap>
        </StyledButton>
      </FormGroupAtom>
    </form>
  );
};

export default LoginForm;

LoginForm.propTypes = {
  router: PropTypes.object,
  isMobile: PropTypes.bool,
  onsuccess: PropTypes.func,
  onError: PropTypes.func,
};
const ErrorText = styled.span`
  font-size: 14px;
  margin-top: 5px;
  font-family: 'Gilroy regular';
  color: rgb(255, 45, 45);
`;
const errorStyle = css`
  margin-bottom: 0;
  span {
    position: static;
  }
`;

const FormGroupAtom = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      padding: 0;
      margin: 0;
    `}
`;

const FormWrapper = styled.div`
  width: 100%;
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      position: relative;
    `}
`;

const Input = styled(ValidatedInput)`
  height: 46px;
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      font-family: ${theme.font.family.websiteMedium};
      font-size: 15px;
      line-height: 22px;
      color: var(--dark-values-black);
      border-radius: 4px;
      border: 1px solid #e3e3e4;
      background-color: var(--light-values-white) !important;
      ::placeholder {
        font-family: ${({ theme }) => theme.font.family.websiteMedium};
        color: var(--grayscale-n400);
      }
    `}
`;

const PasswordWrapper = styled.div`
  margin-top: 40px;
  position: relative;
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      margin-top: 32px;
    `}
`;

const FormError = styled.div`
  color: #ff2d2d;
  font-size: 14px;
  padding-top: 5px;
`;

const SignInButton = styled((p) => <Button {...p} />)`
  text-align: center;
  border: 0;
  font-weight: 700;
  border-radius: 27px;
  height: 55px;
  width: 100%;
  z-index: 30;
  margin: 45px auto 30px;
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.77;
  letter-spacing: 0.78px;
  text-align: center;
  border: 1px solid #0087c1;
  background: #0087c1;
  color: var(--light-values-white);
  transition: all 0.2s;
  &:hover {
    border-color: #1a5885;
    background: #1a5885;
    color: var(--light-values-white);
  }
  padding: 0;
  text-transform: uppercase;
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      padding: 13px 0;
      width: 100%;
      background: #1688fe;
      border-radius: 4px;
      font-family: ${theme.font.family.websiteSemiBold};
      font-size: 15px;
      line-height: 22px;
      border: none;
      text-transform: capitalize;
      margin: 24px 0;
    `}
`;
const StyleHeading = styled(Heading)`
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      font-family: ${theme.font.family.websiteSemiBold};
      font-size: 24px;
      line-height: 30px;
      text-align: start;
      letter-spacing: 0;
      color: var(--dark-values-black);
    `}
`;

const StyleSubHeading = styled(SubHeading)`
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      margin: 0;
      margin-top: 30px;
      margin-bottom: 40px;
      font-family: ${theme.font.family.websiteMedium};
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0;
      text-align: start;
      color: var(--dark-values-dark-regular);
    `}
`;

const StyledButton = styled(Button)`
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      font-size: 15px;
      color: #1688fe;
    `}
`;

const TextWrap = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      font-family: ${theme.font.family.websiteMedium};
    `}
`;
