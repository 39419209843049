import React from 'react';

const JobtrackEmailIcon = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5068_51960)">
        <path
          d="M31.9992 63.9985C49.6719 63.9985 63.9985 49.6719 63.9985 31.9992C63.9985 14.3265 49.6719 0 31.9992 0C14.3265 0 0 14.3265 0 31.9992C0 49.6719 14.3265 63.9985 31.9992 63.9985Z"
          fill="#EDF4FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.9568 3.42383L8.70312 21.0611L24.3155 24.1742L59.9568 3.42383Z"
          fill="#1688FE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.70312 21.0609L24.3155 24.174L34.6778 18.1418L13.7001 19.3407L8.70312 21.0609Z"
          fill="#5DDFA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.3164 24.1742L26.7353 42.5783L59.9577 3.42383L24.3164 24.1742Z"
          fill="#0072E8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.3164 24.174L26.7353 42.5782L41.5838 25.0773L39.0209 20.9018L33.9421 25.141L34.6787 18.1418L24.3164 24.174Z"
          fill="#008B5D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.9567 3.42383L29.9217 28.4967L26.7344 42.5783L59.9567 3.42383Z"
          fill="#ECF0F1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.9411 25.141L29.9217 28.4966L26.7344 42.5782L41.5829 25.0774L39.02 20.9019L33.9411 25.141Z"
          fill="#37B982"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.7401 38.3709L59.9569 3.42383L29.9219 28.4967L49.7401 38.3709Z"
          fill="#1688FE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.8984 28.4966L49.7182 38.3708L50.4927 35.7958L40.4487 19.7075L29.8984 28.4966Z"
          fill="#5DDFA9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.8984 28.4966L30.5729 28.8331L40.7533 20.1955L40.4487 19.7075L29.8984 28.4966Z"
          fill="#ADFADA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.959 3.42383L59.1179 3.71331L33.707 18.1981L34.6785 18.142L59.959 3.42383Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.7043 18.1979L23.1602 23.9436L24.3135 24.174L34.6758 18.1418L33.7043 18.1979Z"
          fill="#ADFADA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.1113 3.56933L59.9583 3.42383L40.4492 19.7076L40.7539 20.1957L60.1113 3.56933Z"
          fill="white"
        />
        <path d="M6.46875 29.4516L14.1605 25.1245L14.6304 25.9626L6.93708 30.2897L6.46875 29.4516Z" fill="#5DDFA9" />
        <path d="M20.1172 47.7724L25.3946 43.4453L26.0023 44.1849L20.725 48.512L20.1172 47.7724Z" fill="#5DDFA9" />
        <path d="M40.293 43.2224L44.9671 38.8953L45.6173 39.5985L40.9432 43.9256L40.293 43.2224Z" fill="#5DDFA9" />
      </g>
      <defs>
        <clipPath id="clip0_5068_51960">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default JobtrackEmailIcon;
