import styled, { css } from 'styled-components';

const SubHeading = styled.p`
  font-family: ${({ theme }) => theme.font.family.websiteRegular};
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.78px;
  text-align: center;
  color: #737980;
  margin-top: 30px;
  margin-bottom: 40px;

  span {
    color: #0687c1;
    display: inline-flex;
    align-items: center;
    margin-left: 3px;
    cursor: pointer;
    svg {
      margin-left: 4px;
    }
  }

  ${(p) =>
    p.isMobile &&
    css`
      font-size: 12px;
      margin-top: 24px 0;
    `}
`;

export default SubHeading;
