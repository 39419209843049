import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withRouter } from 'next/router';

import Flex from '/imports/core/ui/atoms/Flex';
import { getBrandName, getJobTrackRoute, isJobTrackEnable } from '/lib/helpers';
import Jobtracklogo from '/imports/newHomePage/ui/atmos/Logo';
import Link from '/components/Link';
import List from '/imports/core/ui/atoms/List';
import ListItem from '/imports/core/ui/atoms/ListItem';
import Logo from '/imports/core/ui/components/Logo';
import { withIntl } from '/imports/core/api/useIntl';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';

@withIntl
@withResponsiveContext
class Navbar extends PureComponent {
  static propTypes = {
    currentUser: PropTypes.object,
    hideLinks: PropTypes.bool,
    transparent: PropTypes.bool,
    t: PropTypes.func,
    host: PropTypes.string,
  };

  state = { open: false };

  toggleNavigation = () => this.setState((st) => ({ open: !st.open }));

  render() {
    const { open } = this.state;
    const { currentUser, hideLinks, transparent, t, host } = this.props;
    const { href, as } = getJobTrackRoute(this.props.host, 'resumes');
    const isJobTrack = isJobTrackEnable();
    return (
      <TopHeader transparent={transparent}>
        <div>
          {isJobTrack ? (
            <Link href="/">
              <LogoWrapper alignItems="center">
                <Jobtracklogo />
                <Text>{getBrandName(true, host)}</Text>
              </LogoWrapper>
            </Link>
          ) : (
            <Logo />
          )}
        </div>
        {!hideLinks && (
          <Fragment>
            <MenuButton open={open} toggleNavigation={this.toggleNavigation} />
            <Menu open={open}>
              <MenuItem>
                <Link href={currentUser ? href : '/sign-in'} passHref as={as}>
                  <StyledLink>
                    {currentUser && currentUser.personalData && currentUser.personalData.firstName
                      ? currentUser.personalData.firstName
                      : t('signin')}
                  </StyledLink>
                </Link>
              </MenuItem>
            </Menu>
          </Fragment>
        )}
      </TopHeader>
    );
  }
}

const MenuButton = ({ open, toggleNavigation }) => {
  return open ? (
    <MenuIcon
      onClick={toggleNavigation}
      fill="#000000"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </MenuIcon>
  ) : (
    <MenuIcon
      onClick={toggleNavigation}
      fill="#000000"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </MenuIcon>
  );
};

MenuButton.propTypes = {
  open: PropTypes.bool,
  toggleNavigation: PropTypes.func,
};

const LogoWrapper = styled(Flex)`
  gap: 10px;
  cursor: pointer;
`;

const Text = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-size: 17px;
  line-height: 22px;
  color: var(--grayscale-n700);
`;

const TopHeader = styled(Flex)`
  background: ${(p) => (p.transparent ? 'transparent' : p.theme.colors.darkBlack)};
  text-align: left;
  color: var(--light-values-white);
  padding: 3px 10px;
  align-items: center;

  font-family: ${({ theme }) => theme.font.family.tahoma};
  margin: 0;
  > div {
    &:nth-child(1) {
      flex-grow: 1;
    }
  }
  ${({ theme }) => theme.max('sm')`
    padding: 10px;
  `}
  ${({ theme }) => theme.max('xs')`
    line-height: 2.4rem;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  `}
   ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      text-align: right;
    `}
    ${({ theme }) =>
    theme.isJobTrack &&
    css`
      padding: 0 !important;
    `}
  a {
    text-decoration: none;
  }
`;

const Menu = styled(List)`
  margin: 0;
  ${({ theme }) => theme.max('sm')`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: ${({ theme }) => theme.zIndex.headerFixed};
    top: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.88);
    transform: translateX(100vw);
    padding: 20px 0 0 0;
    visibility: hidden;
    ${(p) =>
      p.open &&
      css`
        visibility: visible;
        // transition: all .4s ease;
        transform: translateX(0);
      `}
  `}
`;

const MenuItem = styled(ListItem)`
  display: inline-block;
  padding: 8px 18px;
  &:last-child {
    padding-right: 0;
  }
  ${({ theme }) => theme.max('sm')`
    display: block;
    padding: 20px;
    &:last-child {
      padding-right: 18px;
    }
  `}
`;

const StyledLink = styled.div`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  ${({ theme }) => theme.max('sm')`
    padding: 20px 0;
    font-size: 16px;
  `}
`;

const MenuIcon = styled.svg`
  z-index: ${({ theme }) => theme.zIndex.headerFixed + 1};
  position: relative;
  width: 34px;
  height: 34px;
  fill: #fff;
  display: none;
  cursor: pointer;
  ${({ theme }) => theme.max('sm')`
    display: inline-block;
  `}
`;

export default withRouter(Navbar);
