import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';
import { FreeAccountIcon } from '/imports//dashboard/ui/assets';
import { GmailIcon, InfoIcon } from '/imports/core/ui/assets';
import { Heading, SubHeading } from '/imports/login/ui/atoms';
import { isJobTrackEnable, staticFile } from '/lib/helpers';
import JobtrackEmailIcon from '/imports/dashboard/ui/assets/JobtrackEmailIcon';
import JobtrackGmailIcon from '/imports/core/ui/assets/JobtrackGmailIcon';
import Link from '/imports/core/ui/atoms/Link';
import useIntl from '/imports/core/api/useIntl';

const LoginSuccess = ({ isMobile, isGmailEmail, onPromotionOpen }) => {
  const [displayPromotionEmailPopup, setDisplayPromotionEmailPopup] = useState(false);
  const { t } = useIntl();
  const displayPromotionPopup = () => {
    setDisplayPromotionEmailPopup(true);
    onPromotionOpen();
  };
  const isJobTrack = useMemo(() => isJobTrackEnable(), []);
  const renderGmailPopup = () => {
    return (
      <Fragment>
        <IconWrapper>{isJobTrack ? <JobtrackEmailIcon /> : <FreeAccountIcon />}</IconWrapper>
        <StyledHeading isGmail>{t('signin_success')}</StyledHeading>
        <StyledSubHeading isGmail>
          <span dangerouslySetInnerHTML={{ __html: t('primary_email_info') }} />
          <ButtonWrapper fullWidth justifyContent="center" alignItems="center">
            <button onClick={displayPromotionPopup}>
              {t('login.click_here')} {!isJobTrack && <InfoIcon />}
            </button>
          </ButtonWrapper>
        </StyledSubHeading>
      </Fragment>
    );
  };

  const renderPromotionEmailPopup = () => {
    return (
      <Fragment>
        <StyledHeading isPromotion>{t('login.promotion_box')}</StyledHeading>
        <StyledImage src={staticFile('img/gmail.png')} />
        <GmailButtonWrapper>
          <GmailButton href="https://mail.google.com/mail" target="_blank">
            {isJobTrack ? <JobtrackGmailIcon /> : <GmailIcon />} <span>{t('login.go_to_gmail')}</span>
          </GmailButton>
        </GmailButtonWrapper>
        <StyledSubHeading isPromotion>{t('login.not_promotion_info')}</StyledSubHeading>
      </Fragment>
    );
  };

  if (!isGmailEmail) return <StyledHeading isMobile={isMobile}>{t('signin_success')}</StyledHeading>;

  if (!displayPromotionEmailPopup) return renderGmailPopup();

  return renderPromotionEmailPopup();
};

LoginSuccess.propTypes = {
  isMobile: PropTypes.bool,
  isGmailEmail: PropTypes.bool,
  onPrspanomotionOpen: PropTypes.func,
};

const IconWrapper = styled.div`
  width: 80px;
  height: auto;
  margin: 0 auto;
`;

const ButtonWrapper = styled(Flex)`
  margin-top: 16px;
`;

const StyledHeading = styled(Heading)`
  ${(p) =>
    (p.isGmail || p.isPromotion) &&
    css`
      font-family: ${({ theme }) => theme.font.family.websiteBold};
      font-size: 30px;
      font-weight: 600;
      color: ${p.theme.colors.black};
    `}
  ${(p) =>
    (p.isGmail || p.isPromotion) &&
    p.theme.isJobTrack &&
    css`
      font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
      font-size: 24px;
      line-height: 30px;
      color: var(--dark-values-black);
    `}
  ${(p) =>
    p.isPromotion &&
    css`
      letter-spacing: normal;
    `}
`;

const StyledSubHeading = styled(SubHeading)`
  ${(p) =>
    (p.isGmail || p.isPromotion) &&
    css`
      font-family: ${({ theme }) => theme.font.family.websiteMedium};
      font-size: 19px;
      line-height: 1.37;
      letter-spacing: normal;
      margin-top: 10px;
      margin-bottom: 10px;
      color: ${p.theme.colors.black};

      span {
        display: inline;
        color: ${p.theme.colors.black};
      }

      button {
        display: inline;
        background-color: transparent;
        border: 0;
        color: #0687c1;
        appearance: none;
      }
    `}
  ${(p) =>
    (p.isGmail || p.isPromotion) &&
    p.theme.isJobTrack &&
    css`
      font-size: 15px;
      line-height: 22px;
      margin-top: 16px;
      color: var(--dark-values-dark-regular);

      span {
        color: var(--dark-values-dark-regular);
        cursor: default;
      }

      button {
        display: inline;
        background-color: transparent;
        border: 0;
        font-size: 15px;
        line-height: 22px;
        font-family: ${({ theme }) => theme.font.family.websiteMedium};
        color: #1688fe;
        appearance: none;
        cursor: pointer;
      }
    `}
`;

const StyledImage = styled.img`
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  height: auto;
`;

const GmailButtonWrapper = styled(Flex)`
  justify-content: center;
`;

const GmailButton = styled(Link)`
  border-radius: 3px;
  border: solid 2px #d93025;
  background: transparent;
  font-family: TTCommons;
  font-size: 19px;
  text-align: center;
  color: #d93025;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  justify-content: center;
  text-decoration: none;
  svg {
    margin-right: 5px;
  }
  span {
    margin-top: 6px;
  }
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      border: 1px solid #ea4435;
      border-radius: 4px;
      padding: 9px 20px;
      font-family: ${theme.font.family.websiteMedium};
      font-size: 15px;
      line-height: 22px;
      span {
        margin: 0;
      }
    `}
`;

export default LoginSuccess;
