import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withRouter } from 'next/router';

import Box from '/imports/core/ui/atoms/Box';
import Container from '/imports/core/ui/atoms/Container';
import Flex from '/imports/core/ui/atoms/Flex';
import LoginError from '/imports/login/ui/components/LoginError';
import LoginForm from '/imports/login/ui/components/LoginForm';
import LoginHeader from '/imports/core/ui/components/MainHeader';
import LoginSuccess from '/imports/login/ui/components/LoginSuccess';
import Logo from '/imports/core/ui/components/Logo';
import Navbar from '/imports/core/ui/components/Navbar';
import Page from '/imports/core/ui/atoms/Page';
import { ResponsiveConsumer, useResponsive } from '/imports/core/api/responsiveContext';
import { staticFile, isJobTrackEnable } from '/lib/helpers';
import useMountedState from '/imports/core/api/useMountedState';
import useTracking from '/imports/core/hooks/useTracking';

const LoginPage = ({ router }) => {
  const { renderIfMounted } = useMountedState();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [promotionPopupOpen, setPromotionPopupOpen] = useState(false);
  const { host } = useResponsive();
  const { trackEvent } = useTracking();
  const isJobTrack = isJobTrackEnable();
  useEffect(() => {
    trackEvent('login_page');
  }, []);

  const isGmailEmail = () => {
    if (!success || !email) return false;
    return email.toLowerCase().endsWith('@gmail.com');
  };

  const getPopup = () => {
    if (!isGmailEmail()) {
      return null;
    }
    return promotionPopupOpen ? 'promotion' : 'gmail';
  };

  const onPromotionOpen = () => {
    setPromotionPopupOpen(true);
  };

  const onSuccess = (email) => {
    setEmail(email);
    setSuccess(true);
  };

  const onError = (error) => {
    setError(error);
  };
  return renderIfMounted(
    <React.Fragment>
      {(host === 'cvdeboss' || host === 'modeles-cv') && (
        <Head>
          <title>Identifiez-vous</title>
          <meta
            name="description"
            content="Saisissez votre adresse mail et nous vous enverrons un lien pour vous connecter à votre compte."
          />
        </Head>
      )}
      <ResponsiveConsumer>
        {({ isMobile }) => {
          return (
            <PageExt isMobile={isMobile}>
              <LoginHeader transparent isMobile={isMobile}>
                {isMobile && !isJobTrack ? <Logo /> : <Navbar transparent hideLinks />}
              </LoginHeader>
              <ContainerComp isMobile={isMobile} success={success}>
                <ContentSection
                  isMobile={isMobile}
                  isGmail={isGmailEmail()}
                  success={success || error}
                  popup={getPopup()}
                >
                  <Flex>
                    <BoxWide md={4} sm={8} xs={12}>
                      <InnerBox isMobile={isMobile} popup={getPopup()}>
                        {error && <LoginError isMobile={isMobile} />}
                        {success && (
                          <LoginSuccess
                            isMobile={isMobile}
                            isGmailEmail={isGmailEmail()}
                            onPromotionOpen={onPromotionOpen}
                          />
                        )}
                        {!success && !error && <LoginForm router={router} onSuccess={onSuccess} onError={onError} />}
                      </InnerBox>
                    </BoxWide>
                  </Flex>
                </ContentSection>
              </ContainerComp>
            </PageExt>
          );
        }}
      </ResponsiveConsumer>
    </React.Fragment>,
  );
};

LoginPage.propTypes = {
  isMobile: PropTypes.bool,
  router: PropTypes.object,
};

const PageExt = styled(Page)`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  background-size: 680px 582px;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${(p) => p.theme.colors.gray.lighter2};
    background-repeat: no-repeat;
    background-position: bottom right;
    background-image: url(${staticFile('img/login/Without-BG.svg')});
    position: absolute;
    z-index: -1;
    ${({ theme: { isRTL } }) =>
      isRTL &&
      css`
        transform: scaleX(-1);
      `}
    ${({ theme }) =>
      theme.isJobTrack &&
      css`
        background-color: var(--light-values-white);
        background-image: none;
      `}
  }
  ${(p) =>
    p.isMobile &&
    !p.theme.isJobTrack &&
    css`
      background-color: #fafbfc;
      background-size: 128px 254px;
      background-position: top left;
      background-repeat: no-repeat;
      background-image: url(${staticFile('img/login/mobile-bg.svg')});
    `}
`;

const ContainerComp = styled(Container)`
  display: flex;
  flex: 2 1 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 10px;
  font-family: ${(p) => p.theme.font.family.openSans};
  ${(p) => p.theme.max('xs')`
    padding: 0;
  `}

  ${(p) =>
    p.isMobile &&
    p.success &&
    css`
      flex: unset;
      height: 80vh;
    `}
`;

const BoxWide = styled((p) => <Box {...p} />)`
  flex: 1;
`;

const InnerBox = styled.div`
  padding: 50px 70px;
  ${({ theme }) => theme.max('xs')`
    padding: 25px 15px;
    border-radius: 0;
  `}
  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      width: 100%;
      padding: 0;
      @media (min-width: 768px) {
        padding: 32px 48px;
      }
    `}
  
  ${(p) =>
    p.popup &&
    css`
      padding: 50px;
    `}

  ${(p) =>
    p.isMobile &&
    !p.theme.isJobTrack &&
    css`
      padding: 0 30px !important;
    `}
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 450px;
  margin: 0;
  background: white;
  border-radius: ${(p) => p.theme.general.borderRadius};
  box-shadow:
    0 15px 35px rgba(50, 50, 93, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.07);

  ${({ theme }) =>
    theme.isJobTrack &&
    css`
      width: 100%;
      border-radius: 16px;
      box-shadow: none;
      @media (min-width: 768px) {
        border: 1px solid var(--grayscale-n200);
        max-width: 596px;
      }
    `}

  ${({ isGmail }) =>
    isGmail &&
    css`
      max-width: 610px;
    `}
  
  &::after {
    content: '';
    clear: both;
    display: block;
  }

  ${(p) =>
    p.isMobile &&
    css`
      background-color: inherit;
      box-shadow: none;
    `}
  ${(p) =>
    p.popup &&
    css`
      max-width: ${p.popup === 'gmail' ? '610px' : '950px'};
    `}
`;

export default withRouter(LoginPage);
