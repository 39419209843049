import React from 'react';

const JobtrackGmailIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M8.29103 9.55344L1.94603 5.51559C1.618 5.29866 1.38829 4.96158 1.30636 4.57694C1.22442 4.1923 1.29683 3.79087 1.50797 3.45909C1.71912 3.12731 2.05212 2.89172 2.43526 2.80306C2.81841 2.71439 3.22105 2.77974 3.55648 2.98504L8.21148 5.94719L12.391 3.02144C12.7171 2.80047 13.117 2.71663 13.5044 2.78804C13.8918 2.85945 14.2355 3.08037 14.4614 3.40309C14.6873 3.72581 14.7771 4.12442 14.7116 4.51283C14.646 4.90125 14.4303 5.24828 14.111 5.47899L8.29103 9.55344Z"
        fill="#EA4435"
      />
      <path
        d="M13.75 13.25H11.75V4.25C11.75 3.85218 11.908 3.47064 12.1893 3.18934C12.4706 2.90804 12.8522 2.75 13.25 2.75C13.6478 2.75 14.0294 2.90804 14.3107 3.18934C14.592 3.47064 14.75 3.85218 14.75 4.25L14.75 12.25C14.75 12.5152 14.6446 12.7696 14.4571 12.9571C14.2696 13.1446 14.0152 13.25 13.75 13.25Z"
        fill="#00AC47"
      />
      <path
        d="M14.7283 4.03272C14.7239 4.00272 14.7243 3.97207 14.718 3.94212C14.7084 3.89622 14.6906 3.85382 14.6769 3.80952C14.6636 3.75882 14.6476 3.70887 14.629 3.65987C14.619 3.63612 14.6036 3.61527 14.5922 3.59217C14.5611 3.52552 14.5251 3.46127 14.4845 3.39992C14.4645 3.37087 14.4395 3.34612 14.4174 3.31862C14.3795 3.26864 14.3385 3.22107 14.2947 3.17617C14.2614 3.14382 14.2235 3.11677 14.1874 3.08762C14.1489 3.05468 14.1089 3.02369 14.0674 2.99477C14.0277 2.96887 13.9843 2.94892 13.9424 2.92682C13.8982 2.90377 13.8549 2.87867 13.8089 2.86027C13.7644 2.84237 13.717 2.83097 13.6706 2.81732C13.6241 2.80367 13.5779 2.78732 13.5302 2.77847C13.4711 2.76898 13.4115 2.76297 13.3517 2.76047C13.3138 2.75782 13.2762 2.75117 13.2381 2.75147C13.1673 2.75354 13.0968 2.76064 13.0271 2.77272C12.999 2.77692 12.9706 2.77657 12.9427 2.78237C12.8483 2.8112 12.7542 2.84087 12.6604 2.87137C12.6347 2.88237 12.6121 2.89872 12.5872 2.91112C12.3332 3.0296 12.1191 3.21909 11.9706 3.45667C11.822 3.69426 11.7455 3.96977 11.7502 4.24992V7.13092L14.1102 5.47877C14.342 5.32324 14.5237 5.10387 14.6333 4.84721C14.743 4.59055 14.7761 4.30771 14.7283 4.03272Z"
        fill="#FFBA00"
      />
      <path
        d="M2.75 2.75C3.14782 2.75 3.52936 2.90804 3.81066 3.18934C4.09196 3.47064 4.25 3.85218 4.25 4.25V13.25H2.25C1.98478 13.25 1.73043 13.1446 1.54289 12.9571C1.35536 12.7696 1.25 12.5152 1.25 12.25V4.25C1.25 3.85218 1.40804 3.47064 1.68934 3.18934C1.97064 2.90804 2.35218 2.75 2.75 2.75Z"
        fill="#4285F4"
      />
      <path
        d="M1.27125 4.03283C1.27565 4.00283 1.2753 3.97218 1.28155 3.94223C1.29115 3.89633 1.309 3.85393 1.3227 3.80963C1.33598 3.75894 1.35197 3.70901 1.3706 3.66003C1.3806 3.63628 1.396 3.61543 1.4074 3.59233C1.43849 3.52565 1.47449 3.46138 1.5151 3.40003C1.5351 3.37098 1.5601 3.34623 1.5822 3.31873C1.62011 3.26875 1.66109 3.22118 1.7049 3.17628C1.73815 3.14393 1.77605 3.11688 1.81225 3.08773C1.85066 3.05478 1.89071 3.02379 1.93225 2.99488C1.9719 2.96898 2.0153 2.94903 2.05725 2.92693C2.1006 2.90241 2.14516 2.88008 2.19075 2.86003C2.23525 2.84213 2.28265 2.83073 2.32905 2.81708C2.37545 2.80343 2.4217 2.78708 2.4694 2.77823C2.5285 2.76874 2.5881 2.76273 2.6479 2.76023C2.6859 2.75758 2.72345 2.75093 2.76155 2.75123C2.83228 2.75329 2.90278 2.76039 2.9725 2.77248C3.00065 2.77668 3.029 2.77633 3.057 2.78213C3.1054 2.79446 3.15313 2.80928 3.2 2.82653C3.24713 2.83906 3.29361 2.85394 3.33925 2.87113C3.36495 2.88213 3.3875 2.89848 3.4125 2.91088C3.47731 2.94133 3.53984 2.97642 3.5996 3.01588C3.79992 3.15343 3.96375 3.33766 4.07694 3.55268C4.19013 3.7677 4.24929 4.00704 4.2493 4.25003V7.13103L1.8893 5.47898C1.65759 5.3234 1.47591 5.10403 1.36623 4.84738C1.25654 4.59074 1.22355 4.30782 1.27125 4.03283Z"
        fill="#C52528"
      />
    </svg>
  );
};

export default JobtrackGmailIcon;
