import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from '/imports/login/ui/atoms';
import useIntl from '/imports/core/api/useIntl';

const LoginError = ({ isMobile }) => {
  const { t } = useIntl();
  return (
    <Heading isMobile={isMobile}>
      {t('signin_error1')}
      <br />
      {t('signin_error2')}
    </Heading>
  );
};

LoginError.propTypes = {
  isMobile: PropTypes.bool,
};

export default LoginError;
